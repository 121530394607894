body.dark-theme {
    --radio-btn-border-color: #656464;
    --radio-btn-label-color: #FFFFFF;
    --radio-btn-label-unchecked-color: #656464;
}

body.light-theme {
    --radio-btn-border-color: #0F0F0F;
    --radio-btn-label-color: #0F0F0F;
    --radio-btn-label-unchecked-color: #0F0F0F;
}

.custom-radio-btn {
    & .ant-radio {
        display: inline-block;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        border: 1px solid #0F0F0F;
        border-radius: 50%;

        &:not(.ant-radio-checked) {
            border: 1px solid var(--radio-btn-border-color);

            &+span {
                color: var(--radio-btn-label-unchecked-color);
            }
        }

        &.ant-radio-checked {
            background-color: #FB7B34;

            &+span {
                color: var(--radio-btn-label-color);
            }

            & .ant-radio-inner::after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url('../../assets/checkbox-icon.svg') no-repeat center center;
                background-size: contain;
                inset-block-start: 33%;
                transform: scale(0.675)
            }
        }

        &>input.ant-radio-input[type="radio"],
        &>.ant-radio-inner {
            border: none;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border-radius: 50%;
        }

        & .ant-radio-inner:after {
            border-color: #0F0F0F;
            inset-inline-start: 33%;
            inset-block-start: 33%
        }
    }

    & .ant-radio.ant-wave-target+span {
        width: calc(100% - 24px);
    }
}